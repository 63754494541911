.breadcrumbs {
  border-bottom: 1px solid $color-light-ci-green;

  a {
    margin-left: 1rem;
    margin-right: 1rem;

    @media screen and (max-width: 567px) {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .crumb-separator {
    flex-shrink: 0;
    height: 25px;
    width: 25px;
    color: $color-light-ci-green;

    &:last-child {
      display: none;
    }
  }
}