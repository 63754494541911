.page-footer {
  color: $color-white;
  background-color: $color-darkgrey;
  padding: 2rem 0;

  .footer-logo {
    width: 35rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 520px) {
      width: 20rem;
    }

    @media screen and (max-width: 340px) {
      width: 16.5rem;
    }
  }

  .footer-address {
    p {
      max-width: none;
    }

    a {
      color: $secondary;
    }
  }

  .social-media-links {
    justify-content: center;

    @media screen and (max-width: 520px) {
      justify-content: space-around;
    }

    a {
      color: $secondary;
      display: inline-block;
      text-decoration: none;

      @media screen and (min-width: 520px) {
        margin-right: 2rem;
      }
    }
  }

  .footer-navigation {
    margin: 1.5rem 0;

    a {
      color: $secondary;
      display: inline-block;
      margin-right: 2rem;
      position: relative;
      text-transform: uppercase;
      font-size: 1.2rem;
      letter-spacing: 3px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        right: -1rem;
        width: 1px;
        height: 18px;
        background-color: $color-white;
      }

      &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }

  hr {
    background-color: darken($color-white, 25%);
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .subfooter {
    a {
      margin-right: 0;
    }

    .copyright {
      @media screen and (max-width: 576px) {
        text-align: center;
      }
    }

    .opening-hours {
      @media screen and (max-width: 576px) {
        margin-top: 1rem;
      }
    }

    .meta-links {
      text-align: right;

      @media screen and (max-width: 576px) {
        text-align: center;
        margin-top: 1rem;
      }

      a {
        color: $secondary;
      }
    }
  }
}
