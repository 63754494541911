$color-ci-green: #5A5C53;
$color-light-ci-green: #D4CFC5;

$primary: $color-ci-green;
$secondary: $color-light-ci-green;

$color-white: #fff;
$color-black: #000;
$color-lightgrey: #F3EFEA;
$color-darkgrey: #1D2124;

$color-success: #017100;
$color-info: #004D7F;
$color-warning: #FF9300;
$color-danger: #B51700;
$color-dark: $color-darkgrey;
$color-light: $color-lightgrey;

$theme-colors: (
  "primary":    $color-ci-green,
  "secondary":  $color-light-ci-green,
  "success":    $color-success,
  "info":       $color-info,
  "warning":    $color-warning,
  "danger":     $color-danger,
  "dark":       $color-dark,
  "light":      $color-light
);

$grid-breakpoints: (
  xs: 0, // Extra small screen / phone
  sm: 576px, // Small screen / phone
  md: 768px, // Medium screen / tablet
  lg: 992px, // Large screen / desktop
  xl: 1460px // Extra large screen / wide desktop
);

$grid-breakpoint-xs: map_get($grid-breakpoints, "xs");
$grid-breakpoint-sm: map_get($grid-breakpoints, "sm");
$grid-breakpoint-md: map_get($grid-breakpoints, "md");
$grid-breakpoint-lg: map_get($grid-breakpoints, "lg");
$grid-breakpoint-xl: map_get($grid-breakpoints, "xl");


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1400px
);