.info-section {
  background-color: lighten($color-light-ci-green, 10%);
  padding: 4rem 0;
  position: relative;
  overflow: hidden;

  .infosection-image {
    @media screen and (max-width: 520px) {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }

  .background-pattern {
    position: absolute;
    left: -50%;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.1;
    width: 75%;
      z-index: 0;

    &.animation {
      animation: rotation 180s infinite linear;
      top: 0;

      @keyframes rotation {
        0% {
          transform: rotate(360deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    }
  }

  .icon-rows {
    margin-top: 3rem;

    .icon-row {
      h3 {
        font-family: "nobel", sans-serif;
        font-size: 1.5rem;
        color: $primary;
        letter-spacing: 3px;
      }

      i {
        font-size: 3rem;
        
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          font-size: 2rem;
        }

        @media screen and (min-width: 1524px) {
          font-size: 4rem;
        }
      }

      @keyframes pulse {
        0% { transform: scale(1, 1); }
        50% { transform: scale(1.05, 1.05); }
        100% { transform: scale(1, 1); }
      }

      img {
        width: 80%;
        animation: pulse 4s linear infinite;
      }
    }
  }

  .offices-block {
    .office {
      border-bottom: 1px solid $secondary;

      &:last-child {
        border-bottom: 0;
      }

      .office-details {
        h3 {
          font-size: 1.5rem;
          font-family: "nobel", sans-serif;
          color: $primary;
          letter-spacing: 3px;
        }

        p {
          line-height: 1;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  .inquire {
    .inquire-price {
      position: relative;

      &:after {
        display: block;
        content: "";
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 20px;
        height: 20px;
        background-color: $color-white;
      }

      span {
        font-size: 0.8rem;
        text-transform: uppercase;
        color: $color-ci-green;
      }

      .inquire-price-number {
        line-height: 1;
        margin-bottom: 0.5rem;
      }
    }
    .btn {
      padding-left: 2rem;
      transition: flex .25s ease-in-out;
      justify-content: center;

      &:hover {
        flex: 1;
      }
    }
  }
}
