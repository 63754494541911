.component-timeline {
  .timeline-item {
    display: flex;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    .timeline-year {
      //flex: 1; /* enable for centered elements */
      display: flex;
      justify-content: flex-end;
      padding-right: 2rem;
      position: relative;

      @media screen and (max-width: 600px) {
        border-left: 2px solid #c2c2c2;
        padding-left: 2rem;
        padding-right: 0;
        justify-content: flex-start;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: -9px;
        top: 1rem;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        background-color: $color-ci-green;
        border-radius: 100%;
        border: 1px solid $color-light-ci-green;

        @media screen and (max-width: 600px) {
          left: -9px;
          right: auto;
        }
      }

      span {
        align-self: flex-start;
        padding: 0.5rem 1.5rem;
        background-color: $color-ci-green;
        display: inline-block;
        color: $color-white;
        width: 7rem;
        text-align: center;
        border-radius: 4px;

        @media screen and (max-width: 600px) {
          width: 100%;
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
          text-align: left;
        }
      }
    }
    .timeline-content {
      flex: 1;
      border-left: 2px solid #c2c2c2;
      padding-left: 2rem;

      .timeline-content-inner {
        padding: 0.5rem 1.5rem;
        background-color: $color-light-ci-green;
        color: $color-ci-green;
        margin-bottom: 2rem;
        max-width: 780px;
        border-radius: 4px;

        @media screen and (max-width: 600px) {
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;
        }
      }
    }
  }
}