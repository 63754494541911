.content-component {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &.content-component.component-headline + .content-component.component-text {
    padding-top: -3rem;
  }

  @media screen and (max-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;

    &.content-component.component-headline + .content-component.component-text {
      padding-top: -1rem;
    }
  }
}

hr {
  margin-top: 4rem;
  margin-bottom: 4rem;

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.content-component.component-iframe {
  iframe {
    width: 100%;
    height: 35rem;
    border: 0;
  }
}

.badge {
  border-radius: 0;
}



/* steps element */
.steps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;

  .step {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      display: block;
      top: 35px;
      width: 100vw;
      left: 100%;
      height: 1px;
      z-index: -1;
      background-color: #c2c2c2;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    .circle {
      background-color: #c2c2c2;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $color-black;
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 100%;
      border: 10px solid $color-white;

      &.active {
        background-color: $primary;
        color: $color-white;
      }
    }
  }
}

/* Tingo product checkout styling */
.cost-overview {
  table {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: none;

    tr {
      border-bottom: 1px solid #c2c2c2;

      &:nth-child(2n) {
        background-color: transparent;
      }

      &:last-child {
        border: none;
      }

      td {
        padding: 0.5rem 0;
      }
    }
  }

  .product-row {
    padding: 0.75rem 1.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #c2c2c2;

    .article {
      font-weight: bold;
    }
    .article-price {
      text-align: right;
    }
  }

  .total {
    table {
      border: 1px solid #c2c2c2;
      font-size: 1.5rem;

      tr {
        vertical-align: top;
      }

      td {
        padding: 0.75rem 1.5rem;
      }
    }
  }
}