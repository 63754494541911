.content-component.component-cta {
  &.bg-primary,
  &.bg-dark {
    color: $color-white;
    border: none !important;

    .btn {
      @extend .btn-light;
    }
  }
}