table {
  width: 100%;
  border: 1px solid #f2f2f2;
  margin-top: 1rem;
  margin-bottom: 1rem;

  caption {
    font-size: 1rem;
    margin-top: 0rem;
  }

  th {
    padding: 0.5rem 1rem;
    background-color: $primary;
    color: $color-white;
  }

  tr {
    &:nth-child(2n) {
      background-color: lighten($secondary, 10%);
    }

    td {
      padding: 0.5rem 1rem;
    }
  }
}