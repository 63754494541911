.main-header {

  .navbar {
    border-top: 8px solid $color-light-ci-green;
    border-bottom: 1px solid $color-light-ci-green;
    padding: 0;

    @media screen and (max-width: 990px) {
      height: auto;
    }

    .navbar-toggler {
      padding: 0.5rem 1rem;
      border: none;
      border-radius: unset;
    }

    .navbar-brand {
      padding-left: 1rem;
      margin-right: 2rem;

      img {
        width: 12rem;

        @media screen and (max-width: 1370px) {
          width: 9rem;
          padding-bottom: 1rem;
          padding-top: 1rem;
        }

        @media screen and (max-width: 990px) {
          width: 8rem;
          padding-bottom: 1rem;
          padding-top: 1rem;
        }
      }
    }
  }

  #mainnavigation {
    .navbar-nav {
      height: 7rem;

      @media screen and (max-width: 990px) {
        height: auto;
      }

      .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 auto;
        border-top: 8px solid $color-light-ci-green;
        margin-top: -8px; //negative amount of border-top
        background-color: $color-white;
        transition: border-color 0.25s ease-in-out;

        &:hover,
        &.active {
          border-top-color: $color-ci-green;
          border-bottom-color: $color-white;

          .nav-link {
            border-left: 1px solid $color-light-ci-green;
            border-right: 1px solid $color-light-ci-green;

            @media screen and (max-width: 990px) {
              border: none;
            }
          }
        }

        &.active {
          margin-bottom: -1px;

          @media screen and (max-width: 990px) {
            margin-bottom: 0;
          }

          .open-icon {
            &:before,
            &:after {
              background-color: $color-ci-green;
            }
            &:before {
              transform: rotate(90deg);
            }
          }
        }

        &:last-child {
          border-top: 8px solid $color-ci-green;
          border-bottom: 1px solid $color-ci-green;
          margin-bottom: -1px; //overlay border
          background-color: $color-ci-green;
          color: $color-white;
          //background-image: url("../../images/logo_circle_pattern_inverted.png");
          background-position: 0;
          background-size: cover;
          background-repeat: no-repeat;

          .nav-link {
            color: $color-white;
            border-left: 1px solid $color-ci-green;
            border-right: 1px solid $color-ci-green;

            .open-icon {
              display: none;
            }
          }
        }

        @media screen and (max-width: 990px) {
          margin-top: 0;
          border-top: none;
          padding: 0.75rem 1.5rem;
          flex-direction: column;
        }

        .nav-link {
          padding: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
            text-decoration: none;
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
          transition: color 0.25s ease-in-out;
          cursor: pointer;

          @media screen and (max-width: 990px) {
            justify-content: flex-start;
            border: none;
          }

          .open-icon {
            display: inline-block;
            margin-left: 0.5rem;
            position: relative;

            &:before,
            &:after {
              position: absolute;
              right: -4px;
              top: -5px;
              content: "";
              display: block;
              width: 1px;
              height: 7px;
              background-color: lighten($color-ci-green, 10%);

              @media screen and (max-width: 1370px) {
                right: -1px;
              }
            }
            &:after {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  .navbar-collapse {
    @media screen and (max-width: 990px) {
      padding-top: 1rem;
      border-top: 1px solid $secondary;
    }
  }

  /* overrides for mobile navigation */
  .mobile-navigation {
    display: none;

    @media screen and (min-width: 990px) {
      display: none !important;
    }

    .nav-item {
      border: none !important;

      &:last-child {
        background-color: transparent !important;
        color: $primary !important;

        .nav-link {
          border: none !important;
          color: lighten($primary, 10%) !important;
        }
      }

      .nav-link {
        text-transform: none !important;
      }
    }
  }
}

.navigation-dropdown {
  padding: 2rem 0;
  border-bottom: 1px solid $color-light-ci-green;
  display: none;

  @media screen and (max-width: 990px) {
    display: none !important;
  }

  .child-block {
    margin-right: 3rem;

    h3 {
      font-family: "nobel", sans-serif;
      text-transform: none;
      font-weight: 200;
      font-size: 1.5rem;
    }

    h2,
    p {
      color: $color-ci-green;
      max-width: none;
    }
  }
}

.portal {
  .main-header {
    #mainnavigation {
      .navbar-nav {
        .nav-item {
          &:last-child {
            border-top: inherit;
            border-bottom: inherit;
            margin-bottom: 0;
            color: inherit;
            background-image: none;
            background-color: inherit;
            border-top: 8px solid $color-light-ci-green;

            &:hover {
              border-top-color: $primary;

              .nav-link {
                color: rgba($primary, 1);
              }
            }

            .nav-link {
              color: rgba($primary, 0.75);
              border-left: 1px solid transparent;
              border-right: 1px solid transparent;

              &:hover {
                border-left: 1px solid $color-light-ci-green;
                border-right: 1px solid $color-light-ci-green;
              }
            }
          }
        }
      }
    }
  }

  .navbar {
    margin-bottom: 3rem;
    border-bottom: 1px solid $secondary;
    border-top: 8px solid $color-light-ci-green;

    .btn {
      margin-right: 1rem;
      margin-bottom: 0;

      &:last-of-type(){
        margin-right: 0;
      }
    }
  }

  .teaser {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .teaser-title {
      font-family: "nobel", sans-serif;
      font-size: 1.5rem;
      color: $primary;
    }
  }
}

.subnavigation {
  border-bottom: 1px solid $color-light-ci-green;
  padding: 2.5rem;

  @media screen and (max-width: 520px) {
    padding: 1rem 0.5rem;
  }

  .subnav-title {
    padding-top: 1.25rem;
    margin-bottom: 0.5rem;
    color: $color-light-ci-green;
  }

  .subnav-link {

    @media screen and (max-width: 520px) {
      width: 100%;
      margin: 0.5rem 0;
    }

    &.active {
      font-weight: bold;
    }
  }
}
