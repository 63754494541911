.teaser-container {
    padding: 4rem 0;

    .teaser-group {
        position: relative;

        /* teaser group inner -> slider carousel, not having class teaser-group-inner is row layout without slider */
        .teaser-group-inner {
            //reset col margin, class .row breaks the slider!
            //margin-left: -15px;
            //margin-right: -15px;

            .teaser {
                margin-bottom: 0;
                margin-right: 1.5rem;
            }
        }

        .teaser {
            margin-bottom: 4rem;

            @media screen and (max-width: 768px) {
                margin-bottom: 3rem;
            }

            .teaser-inner {
                position: relative;
            }

            .event-image {
                .date-label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 1rem 1.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background-color: $color-white;

                    .month {
                        text-transform: uppercase;
                        line-height: 1;
                        font-size: 1rem;
                        margin-bottom: -5px;
                    }
                    .day {
                        font-size: 2.75rem;
                        line-height: 1;
                        font-family: "playfair-display", serif;
                    }
                }
            }

            img {
                width: 100%;
                margin-bottom: 1.5rem;
                position: relative;
            }

            h2 {
                font-family: "nobel", sans-serif;
                font-size: 1.75rem;
                letter-spacing: 3px;
                word-wrap: break-word;
                //word-break: break-all;

                @media screen and (max-width: 1024px) { font-size: 1.75rem; }
                @media screen and (max-width: 768px) { font-size: 1.5rem; }
                @media screen and (max-width: 520px) { font-size: 1.25rem; }
            }
        }
    }
}

.flickity-prev-next-button {
    border-radius: 0;
    z-index: 999;

    &:disabled {
        display: none;
    }
}
