img {
  display: block;
  max-width: 100%;
}

a img {
  text-decoration: none;
}

.content-image {
  figcaption {
    padding: 0.5rem 1rem;
    background: rgba($color-black, 0.65);
    color: $color-white;
    width: 100%;
  }
}

.image-gallery {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
  grid-auto-columns: 3;
}

.homepage-slider {
  .flickity-page-dots {
    padding-left: 6rem;
    text-align: left;
    bottom: 50px;
    display: none;

    .dot {
      background: $color-white;
    }
  }

  .carousel-cell {
    width: 100vw;
    height: 80vh;

    @media screen and (max-width: 720px) {
      height: 100%;
      min-height: 35rem;
    }

    .slider-content {
      padding-left: 6rem;
      padding-right: 6rem;
      background-color: rgba($color-black, 0.65);

      @media screen and (max-width: 1024px) {
        padding-left: 4rem;
        padding-right: 4rem;
      }

      @media screen and (max-width: 520px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      h1,
      .lead {
        margin-top: 2rem;
        margin-bottom: 2rem;
        color: $color-white;
      }

      h1 {
        @media screen and (max-width: 520px) {
          font-size: 1.75rem;
        }
      }
    }
  }
}

.flickity-prev-next-button {
  height: 70px;
  background: rgba(0, 0, 0, 0.2);

  // @media screen and (max-width: 520px) {
  //   display: none;
  // }

  &.previous {
    left: 0;
  }

  &.next {
    right: 0;
  }

  &:hover {
    .flickity-button-icon {
      fill: $color-black;
    }
  }

  .flickity-button-icon {
    fill: $color-white;
  }
}

.carousel-cell {
  img {
    max-width: none;
  }
}

/* object-fit helper classes */
.object-fit-cover {
    object-fit: cover;
}
.object-fit-contain {
    object-fit: contain;
}
.object-fit-fill {
    object-fit: fill;
}
.object-fit-none {
    object-fit: none;
}
