.debug {
  background-color: black;
  color: lime;
  border: 1px solid lime;
  padding:5px;
  margin-top:30px;
  margin-bottom:15px;
  font-family: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
}

body {
  background-color: $color-white;
  font-family: "nobel", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 200;
  color: $color-black;
}

.alert {
  border-radius: 0;
}