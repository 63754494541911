.component.component-image {

  figure {
    img {

    }
    figcaption {
      font-style: italic;
    }
  }

}