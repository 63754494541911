.download-element {
  width: 18rem;
  
  &:hover {
    background-color: lighten($primary, 10%);

    .download-title,
    .download-link {
      color: $color-white !important;
    }
  }

  .download-link {
    text-decoration: none;

    .download-title {
      font-size: 1.5rem;
      font-family: "nobel", sans-serif;
      color: $primary;
    }
  }
}