.content-component.component-pageteaser {

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }

  .page-teaser {
    height: 30rem;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 4rem;
    text-decoration: none;

    &:nth-child(2n) {
      transform: translateY(2.25rem);

      @media screen and (max-width: 768px) {
        transform: none;
      }
    }

    &:hover {
      .page-teaser-content {
        height: 100%;
        align-items: center;
        text-align: center;
        -ms-flex-align: flex-start; //IE11 width bugfix
      }
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 2rem;
    }

    h2 {
      font-size: 2rem;
    }

    p {
      margin-bottom: 0;
      max-width: 100%;
    }
  }
}