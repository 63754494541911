.rooms {
    padding-top: 4rem;
    padding-bottom: 4rem;

    .rooms-filter {
      border-top: 1px solid $secondary;
      border-bottom: 1px solid $secondary;

      .filter-checkbox {
        margin-right: 2rem;
        display: flex;
        align-items: center;

        label {
          padding-left: 0.5rem;
          margin-bottom: 0;
        }
      }

      #filterDate {
        border: none;
        border-right: 1px solid lightgray;
      }

      .clear-datefilter {
        background-color: $color-white;
      }
    }

    .rooms-overview {
      .room {
        border: none;
        //border-top: 10px solid $secondary;
        position: relative;
        border-radius: 0;

        .room-kategorie {
          position: absolute;
          right: 7px;
          top: 7px;
        }

        .circle-container {
          position: absolute;
          width: 100%;
          bottom: -2.5rem;
          display: flex;
          justify-content: center;
          transform: translateX(-50%); //IE Fix
          left: 50%; //IE Fix
        }

        .icon-circle {
          display: flex;
          flex-direction: column;
          width: 5rem;
          height: 5rem;
          border: 4px solid $color-white;
          border-radius: 100%;
          line-height: 1;
          justify-content: center;
          align-items: center;
        }

        h2 {
          line-height: 1.15;
          font-size: 2rem;
        }
      }
    }
}