//prevent flashing of content
[x-cloak] {
    display: none !important;
}

//body of eguma voucher site
.voucher-site {
    display: grid; 
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;

    .alert {
        max-width: 640px;
        z-index: 99;
        margin: 0 auto;
        background: indianred;
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);

        .alert-heading {
            font-family: "nobel", sans-serif;
            font-size: 1rem;
        }

        @media screen and (max-width: 640px) {
            max-width: auto;
            width: 90%;
        }
    }

    .main-header {
        .navbar {
            .navbar-brand {
                img {
                    &.hdw-logo {
                        width: 8rem;

                        @media screen and (max-width: 768px) {
                            width: 4rem;
                        }
                    }

                    &.web-solutions-logo {
                        width: 18rem;
                
                        @media screen and (max-width: 648px) {
                            width: 10rem;
                        }
                    }
                }
            }
        }
    }

    #scanOverlay {
        position: absolute;
        inset: 0 0 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(29, 163, 29, 0.5);
        z-index: 99;
        opacity: 0;
        color: #fff;
        font-size: 1.5rem;
        font-weight: bold;
        text-shadow: #212529 0 0 5px;

        &.scanned {
            opacity: 1;
        }
    }

    //override hdw style settings for submit buttons    
    #logoutButton {
        color: #212529;
        background-color: #d4cfc5;
        border-color: #d4cfc5;
    }

    footer {
        small {
            font-weight: 300;
        }
    }
}