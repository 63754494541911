.list-group {
  .list-group-item {
    border-radius: 0.25rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.list-unstyled {
  list-style: none;
}

.ordered-list {
  display: flex;
  flex-wrap: wrap;

  .list-item {
    width: 50%;
  }
}

.unordered-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  .list-item {
    //width: 50%;
    list-style-type: none;
    position: relative;
    margin-left: 0;

    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 11px;
      left: -1rem;
      width: 8px;
      height: 2px;
      background-color: $primary;
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
      top: 16px;
    }
  }
}