.events {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: $primary;
  color: $color-white;

  .events-filter {
    border-top: 1px solid $secondary;
    border-bottom: 1px solid $secondary;

    #filterDate {
      border: none;
      border-right: 1px solid lightgray;
        font-weight: 200;
    }

    .clear-datefilter {
      background-color: $color-white;

        &:hover {
          i {
              color: $primary;
          }
        }
    }
  }
}

//override datepicker color
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: $primary !important;
  border-color: $primary !important;
}
