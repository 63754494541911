.text-transform-none {
    text-transform: none;
}

// cookies accordion style
.accordion .open-icon { display: none; }
.accordion .close-icon { display: block; }
.accordion [aria-expanded='true'] .open-icon { display: block; }
.accordion [aria-expanded='true'] .close-icon { display: none; }

.gap-4 {
    gap: 0.5rem;
}