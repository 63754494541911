/* news overview include */
.content-component.component-newsoverview {
  .news-element {
    .news-element-content {
      //border-left: 8px solid $color-white;

      .news-date {
        border-top: 1px solid $secondary;
        border-bottom: 1px solid $secondary;
      }
    }
  }

  .pagination-nav {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    .current,
    .btn {
      padding: 0.75rem 1.5rem;
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
}