.contact-container {
  #googleMaps {
    width: 100%;
    height: 100%;
    min-height: 30rem;
  }

  .route-link {
    position: absolute;
    left: 1rem;
    top: 1rem;

    @media screen and (max-width: 520px) {
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  .map-fontscale {
    font-size: 1.25rem;

    @media screen and (max-width: 520px) {
      font-size: 1rem;
    }
  }

  /* google maps infowindow style overrides */
  .gm-style .gm-style-iw-c {
    border-radius: 0;
  }
  .gm-style-iw-d {
    overflow: hidden !important;
  }
  .gm-style-iw.gm-style-iw-c {
    background-color: rgba(90, 92, 83, 0.80);
  }
  .gm-style .gm-style-iw-t::after {
    display: none;
  }
}