button,
.btn {
  border-radius: 0;
  padding: 0.75rem 1.5rem;
  font-family: "nobel", sans-serif;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: background-color .25s ease-in-out;
  margin-bottom: 0.5rem;

  &:focus {
    outline: none;
  }

  &.btn-outline-secondary {
    color: darken($secondary, 20%);
    border-color: darken($secondary, 20%);

    &:hover {
      color: darken($secondary, 35%);
      border-color: darken($secondary, 35%);
    }
  }
}
a.btn {
    text-decoration: none;
}


/* lightswitch toggle */
.custom-control-input-success:checked ~ .custom-control-label::before {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.custom-control-input-danger:checked ~ .custom-control-label::before {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.custom-control-input-warning:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-input-info:checked ~ .custom-control-label::before {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

/* Large toggle switches */
.custom-switch-lg .custom-control-label::before {
  left: -2.25rem;
  width: 3rem;
  border-radius: 1.5rem;
}
.custom-switch-lg .custom-control-label::after {
  top: calc(.25rem + 3px);
  left: calc(-2.25rem + 4px);
  width: calc(1.5rem - 6px);
  height: calc(1.5rem - 6px);
  border-radius: 1.5rem;
}
.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.4rem);
}
.custom-switch-lg .custom-control-label::before {
  height: 1.5rem;
}
.custom-switch-lg .custom-control-label {
  padding-left: 1.5rem;
  line-height: 1.7rem;
}
