.news-element {
  .news-element-content {
    //border-left: 8px solid $secondary;

    h2 {
      font-size: 1.75rem;
      line-height: 1.15;
    }

    .news-previewtext,
    .news-date {
      color: lighten($primary, 10%);
    }

    .news-date {
      border-top: 1px solid $color-lightgrey;
      border-bottom: 1px solid $color-lightgrey;
    }
  }
}