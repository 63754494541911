.component.component-person {
    .person-grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-gap: 2rem;

        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    .person-wrapper {
        background-color: $color-lightgrey;
        padding: 2rem;
        color: $primary;
        display: inline-block;
    }

    .person-portrait {
        width: 10rem;
        min-width: 10rem;

        @include media-breakpoint-up(md) {
            width: 11rem;
            min-width: 11rem;
        }

        img {
            background-color: $color-white;
            border: 2px solid $color-light-ci-green;
        }
    }

    .person-name {
        font-family: "nobel", sans-serif;
        font-size: 1.5rem;
        margin: 0;
    }
}
