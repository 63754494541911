.component-slideshow {
  padding-top: 0;
  padding-bottom: 0;
  background-color: $color-light-ci-green;

  .slideshow {
    .carousel-cell {
      height: 40rem;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      @media screen and (max-width: 768px) {
        height: 30rem;
      }
      @media screen and (max-width: 520px) {
        height: 22rem;
      }
    }
  }
}