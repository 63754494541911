.component-headline {

  h1, h2, h3 {
    word-break: break-word;
  }

  &.bg-primary {
    color: $color-white;
  }
  &.bg-secondary {
    color: darken($primary, 5%);
  }
  &.bg-white {
    color: $primary;
  }
}