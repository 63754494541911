.page-intro {
    padding-top: 4rem;
    padding-bottom: 4rem;

    h1 {
        color: $color-ci-green;
        margin-bottom: 2rem;
        word-break: break-word;
    }

    .lead {
        color: $color-ci-green;
        margin-bottom: 2.5rem;
    }

    a:not(.btn) {
        font-weight: bolder;

        &:hover {
            color: darken($color-ci-green, 20%);
            text-decoration: none;
        }
    }
}

.subpage-teaser {

    h1 {
        hyphens: auto;
    }

    .subpage-link-element {
        &:before {
            //position: absolute;
            //inset: 0;
            //content: "";
            //display: block;
            //background-color: rgba(255, 255, 255, 0.45);
        }
        span {
            //transform: translateX(-50%) translateY(-50%);
        }
    }
}
