.video-container {
    position: relative;

    &.video-16-9,
    &.video-4-3 {
        width: 100%;
        max-width: 100%;
        padding-bottom: 56.25%;
    }

    &.video-16-9 {
        padding-bottom: 56.25%;
    }

    &.video-4-3 {
        padding-bottom: 75%;
    }

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
