h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'playfair-display', serif;
  text-transform: uppercase;
}

h1 {
  font-size: 3.5rem;
  line-height: 1.15;

  @media screen and (max-width: 1024px) { font-size: 3rem; }
  @media screen and (max-width: 768px) { font-size: 2.75rem; }
  @media screen and (max-width: 520px) { font-size: 2.5rem; }
  @media screen and (max-width: 360px) { font-size: 2.15rem; }
}
h2 {
  font-size: 3rem;
  line-height: 1.2;

  @media screen and (max-width: 1024px) { font-size: 2.5rem; }
  @media screen and (max-width: 768px) { font-size: 2.25rem; }
  @media screen and (max-width: 520px) { font-size: 2rem; }
  @media screen and (max-width: 360px) { font-size: 1.65rem; }
}
h3 {
  font-size: 2.65rem;
  line-height: 1.35;

  @media screen and (max-width: 1024px) { font-size: 2.15rem; }
  @media screen and (max-width: 768px) { font-size: 1.95rem; }
  @media screen and (max-width: 520px) { font-size: 1.65rem; }
  @media screen and (max-width: 360px) { font-size: 1.30rem; }
}
h4 {
  font-size: 2.25rem;
  line-height: 1.45;

  @media screen and (max-width: 1024px) { font-size: 1.75rem; }
  @media screen and (max-width: 768px) { font-size: 1.5rem; }
  @media screen and (max-width: 520px) { font-size: 1.25rem; }
  @media screen and (max-width: 360px) { font-size: 1rem; }
}
h5 {
  font-size: 2rem;
  line-height: 1.5;

  @media screen and (max-width: 1024px) { font-size: 1.5rem; }
  @media screen and (max-width: 768px) { font-size: 1.25rem; }
  @media screen and (max-width: 520px) { font-size: 1rem; }
  @media screen and (max-width: 360px) { font-size: 1rem; }
}
h6 {
  font-size: 1.75rem;
  line-height: 1.65;

  @media screen and (max-width: 1024px) { font-size: 1.25rem; }
  @media screen and (max-width: 768px) { font-size: 1.05rem; }
  @media screen and (max-width: 520px) { font-size: 1rem; }
  @media screen and (max-width: 360px) { font-size: 1rem; }
}

.lead {
  font-size: 1.5rem;
  line-height: 1.35;

  @media screen and (max-width: 1024px) { font-size: 1.4rem; }
  @media screen and (max-width: 768px) { font-size: 1.3rem; }
  @media screen and (max-width: 520px) { font-size: 1.2rem; }
  @media screen and (max-width: 360px) { font-size: 1.15rem; }
}

p {
  max-width: $grid-breakpoint-lg; //prevent text from going full container width
}

a, a:hover {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Firefox */
::-moz-selection {
  color: $color-white;
  background: darken($color-light-ci-green, 15%);
}

::selection {
    color: $color-white;
    background: darken($color-light-ci-green, 15%);
}
