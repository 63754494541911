form {
  max-width: 992px;

  label,
  label.freeform-label {
    font-weight: 200 !important;

    &.required {
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: -15px;
        width: 10px;
        height: 10px;
        content: "*";
        display: block;
        color: $color-warning;
      }
    }
  }

  .freeform-input,
  .form-control {
    border-radius: 0;
    margin-bottom: 1rem;
    border: none;
    border-bottom: 1px solid $primary;
    font-weight: 200;
    font-size: 1.25rem;
    padding: 0.5rem 1.25rem;
    background-color: lighten($color-light-ci-green, 15%);
    height: auto;

    &:disabled,
    &[readonly] {
      background-color: lighten($color-light-ci-green, 15%);
    }

    &:focus {
      outline: none;
      background-color: $color-white;
    }
  }

  input[type="submit"],
  button[type="submit"] {
    @extend .btn;
    @extend .btn-primary;

    margin-top: 1rem;
  }

  input[type="checkbox"],
  input[type="radio"] {
    display:none;
  }
  input[type="checkbox"] + label::before,
  input[type="radio"] + label::before {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 2px solid $color-ci-green;
    background-color: #fff;
    display: block;
    content: "";
    float: left;
    margin-right: 12px;
    margin-top: 7px;
  }
  input[type="checkbox"] + label::before {
    border-radius: 0;
  }
  input[type="checkbox"]:checked+label::before,
  input[type="radio"]:checked+label::before {
    box-shadow: inset 0px 0px 0px 2px #fff;
    background-color: $color-ci-green;
  }

  .form-check-input {
    margin-top: 0.5rem;
  }
}

.bg-primary {
  form {
    input[type="submit"],
    button[type="submit"] {
      @extend .btn-light;
    }
  }
}

.filter-checkbox {
  margin-right: 2rem;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    input ~ .checkbox-checkmark {
      background-color: $light;
    }
  }

  input:checked ~ .checkbox-checkmark {
    background-color: $secondary;
  }
  input:checked ~ .checkbox-checkmark:after {
    display: block;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox-checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $light;

    &:after {
      content: "";
      position: absolute;
      display: none;

      left: 7px;
      top: 5px;
      width: 6px;
      height: 10px;
      border: solid $primary;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}