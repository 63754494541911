.cart {
    .btn {
        &.remove-from-cart {
            margin-bottom: 0;
            margin-top: 0;

            @media screen and (max-width: 768px) {
                margin-top: 1rem;
            }
        }
    }

    label {
        &.product-count {
            margin-right: 2rem;

            @media screen and (max-width: 576px) {
                margin-right: 0;
            }
        }
    }
}